import { Head, usePage } from '@inertiajs/react';
import { ReactNode } from 'react';

import { TranslationsProvider } from '../../Shared/locale';
import NotificationProvider from '../../Shared/Notifier/NotificationProvider';
import { PageProps } from '../types';

export default function Layout({
  children,
}: {
  children?: ReactNode;
}) {
  const { props: { document } } = usePage<PageProps>();

  return (
    <TranslationsProvider>
      <NotificationProvider>
        {document && <Head title={document.title} />}
        {children}
      </NotificationProvider>
    </TranslationsProvider>
  );
}
