import { usePage } from '@inertiajs/react';

import { PageProps } from './types';

export function useRouter() {
  const { props: { locale }, url } = usePage<PageProps>();

  const routes = {
    // Auth: GET
    'login_page': () => `/login?locale=${locale}`,
    // Auth: POST
    'request_login_token': () => '/login',
    'verify_login_token': () => '/login/complete',

    // Registration (back-end)
    'register_page': () => `/b/register?locale=${locale}`,

    // Charity (front-end)
    'charity_page': (charityId: string) => `/${charityId}`,
  };

  const isActive = (route: string) => {
    return url.startsWith(route);
  };

  return {
    routes,
    isActive,
  };
}
