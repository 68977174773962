import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

interface ContainerProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size?: 'sm' | 'md' | 'lg';
}

export default function Container({
  size = 'lg',
  className,
  children,
  ...props
}: ContainerProps) {

  return (
    <div
      className={classNames(
        'container mx-auto p-6',
        { 'max-w-lg': size === 'sm', 'max-w-screen-md': size === 'md', 'max-w-screen-xl': size === 'lg' },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
