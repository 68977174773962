import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { ReactNode } from 'react';
import { ChevronRight, Settings, User as UserIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';

import CampaignCard from '../../Frontend/Campaign/CampaignCard';
import { useRouter } from '../../Frontend/router';
import Container from '../../Frontend/UI/Container';
import Header from '../../Frontend/UI/Header';
import Layout from '../../Frontend/UI/Layout';
import { useMutation } from '../../Shared/api';
import { assetUrl } from '../../Shared/helpers';
import { Campaign, Charity, Project, SharedProps, User } from '../../Shared/types';
import { AButton, Button } from '../../Shared/UI/Button';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { useCharityStyle } from '../../Shared/useCharityStyle';

export default function DashboardPage({
  user,
}: {
  user: User & {
    linked_campaigns: (Campaign & {
      charity: Charity;
      project: Project;
    })[];
    charities: Charity[];
  };
} & SharedProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();

  useCharityStyle();

  const charities = sortBy(uniqBy([
    ...user.linked_campaigns.reduce((charities, campaign) => [
      ...!charities.find((charity) => charity.id === campaign.charity.id) ? [campaign.charity] : [],
      ...charities,
    ], [] as Charity[]),
    ...user.charities,
  ], 'id'), 'title');

  const hasAccess = (charityId: string) => user.charities.find((charity) => charity.id === charityId);

  const [logout] = useMutation(routes.logout(), {
    preserveState: false,
  });

  return (
    <>
      <div className="bg-white shadow-sm relative z-10">
        <Container size="lg" className="!py-0 ">
          <div className="flex items-center justify-between h-[56px]">
            <a href="/" className="flex items-center h-8">
              <img
                src={assetUrl('images/logo.svg')}
                alt={'Supporta'}
                className="h-3.5"
              />
            </a>
            <div className="flex space-x-2">
              <Button
                onClick={() => logout({ redirect_url: window.location.href })}
                variant="tertiary"
                size="sm"
              >
                {t('auth:log_out')}
              </Button>
              <AButton href={routes.select_charity_page()} variant="primary" size="sm">
                {t('auth:start_campaign')}
              </AButton>
            </div>
          </div>
        </Container>
      </div>
      <Header className="bg-slate-50">
        <Container>
          <h1 className="text-2xl">
            {t('auth:welcome_user', { user: user.first_name })}
          </h1>
          <div>
            <Icon className="mr-1">
              <UserIcon />
            </Icon>
            {user.full_name}
          </div>
        </Container>
      </Header>
      <Container>
        {charities.map((charity, index) => {
          const campaigns = user.linked_campaigns.filter((campaign) => campaign.charity.id === charity.id);

          return (
            <div className="space-y-6" key={charity.id}>
              {index > 0 && (
                <hr className="border-t-2 border-slate-200" />
              )}

              <div className="flex items-center justify-between">
                <h2 className="overflow-hidden text-lg whitespace-nowrap text-ellipsis">
                  <a
                    href={charity.url}
                    className="text-slate-800"
                  >
                    {charity.title}
                  </a>
                </h2>

                {charity.logo_url && (
                  <a href={charity.url}>
                    <img src={charity.logo_url} className="h-6 ml-2 rounded-sm" alt={charity.title} />
                  </a>
                )}
              </div>

              {hasAccess(charity.id) && (
                <Card
                  link={{
                    href: routes.backend_page(charity.id),
                    ariaLabel: t('auth:manage_charity'),
                  }}
                  spacing="xs"
                  border
                  className="flex items-center justify-between"
                >
                  <span className="font-medium">
                    <Icon className="mr-2">
                      <Settings />
                    </Icon>
                    {t('auth:manage_charity')}
                  </span>
                  <Icon className="text-slate-400">
                    <ChevronRight />
                  </Icon>
                </Card>
              )}

              {campaigns.length > 0 && (
                <div className="space-y-2">
                  <h5 className="font-medium text-slate-500">
                    {t('auth:your_campaigns', { count: campaigns.length })}
                  </h5>
                  {campaigns.map((campaign) => (
                    <CampaignCard
                      campaign={campaign}
                      charity={campaign.charity}
                      showProject
                      reload
                      key={campaign.id}
                    />
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </Container>
    </>
  );
}

DashboardPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
