import { Link } from '@inertiajs/react';
import { ReactNode } from 'react';
import { ChevronRight } from 'react-feather';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '../../Frontend/UI/Button';
import { setServerErrors, useMutation } from '../../Shared/api';
import { Charity, SharedProps } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import Container from '../../Shared/UI/Container';
import ErrorMessage from '../../Shared/UI/ErrorMessage';
import { Icon } from '../../Shared/UI/Icon';
import { Input } from '../../Shared/UI/Input';
import InputGroup from '../../Shared/UI/InputGroup';
import InputLabel from '../../Shared/UI/InputLabel';
import ServerErrors from '../../Shared/UI/ServerErrors';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { useRouter } from '../router';
import Layout from '../UI/Layout';
import Logo from '../UI/Logo';

export default function CompleteLoginPage({
  charity,
  email,
  params,
}: {
  charity: Charity | null;
  email: string | null;
} & SharedProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();

  const { register, handleSubmit, setError, formState: { errors, defaultValues } } = useForm({
    defaultValues: {
      email,
      redirect_url: params.redirect_url,
      charity_id: typeof params.charity_id === 'string' ? params.charity_id : null,
      token: '',
    },
  });

  const [login, { loading, errors: serverErrors }] = useMutation(routes.verify_login_token(), {
    onError: (errors) => {
      setServerErrors(errors, setError, defaultValues);
    },
  });

  useCharityStyle(charity);

  return (
    <Container size="sm">
      <Logo charity={charity} />

      <Card>
        <form onSubmit={handleSubmit(login)}>
          <div className="space-y-6">
            <div className="space-y-2">
              <h2>
                {t('auth:check_your_email')}
              </h2>
              <p className="text-slate-700">
                <Trans i18nKey="auth:login_code_description" values={{ email }}>
                  <strong />
                </Trans>
              </p>
            </div>

            {serverErrors && <ServerErrors errors={serverErrors} defaultValues={defaultValues} scrollIntoView />}

            <InputGroup>
              <InputLabel required valid={!errors.token} htmlFor="complete_login_login_code">
                {t('auth:login_code')}
              </InputLabel>
              <Input
                {...register('token', { required: true })}
                autoFocus
                id="complete_login_login_code"
                inputMode="numeric"
                aria-invalid={!!errors.token}
              />
              <ErrorMessage attribute={t('auth:login_code')} error={errors.token }/>
            </InputGroup>

            <Button
              type="submit"
              disabled={loading}
              className="block w-full"
              loading={loading}
            >
              {t('shared:form.continue')}
              <Icon className="ml-2">
                <ChevronRight />
              </Icon>
            </Button>
          </div>
        </form>
      </Card>
      <div className="mt-8 text-center">
        <Link href={`${routes.login_page()}`}>
          {t('shared:form.back')}
        </Link>
      </div>
    </Container>
  );
}

CompleteLoginPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
