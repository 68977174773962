import { ReactNode } from 'react';
import { ChevronRight } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../../Frontend/UI/Button';
import { setServerErrors, useMutation } from '../../Shared/api';
import { Charity, SharedProps } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import Container from '../../Shared/UI/Container';
import ErrorMessage from '../../Shared/UI/ErrorMessage';
import { Icon } from '../../Shared/UI/Icon';
import { Input } from '../../Shared/UI/Input';
import InputGroup from '../../Shared/UI/InputGroup';
import InputLabel from '../../Shared/UI/InputLabel';
import ServerErrors from '../../Shared/UI/ServerErrors';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { useRouter } from '../router';
import Layout from '../UI/Layout';
import Logo from '../UI/Logo';

export default function LoginPage({
  charity,
  email,
  params,
}: SharedProps & {
  charity: Charity | null;
  email: string | null;
}) {
  const { t } = useTranslation();
  const { routes } = useRouter();

  const { register, handleSubmit, setError, formState: { errors, defaultValues } } = useForm({
    defaultValues: {
      email: typeof params.email === 'string' ? params.email : email,
      redirect_url: params.redirect_url,
      charity_id: typeof params.charity_id === 'string' ? params.charity_id : null,
    },
  });

  const [login, { loading, errors: serverErrors }] = useMutation(routes.request_login_token(), {
    onError: (errors) => {
      setServerErrors(errors, setError, defaultValues);
    },
  });

  useCharityStyle(charity);

  return (
    <Container size="sm">
      <Logo charity={charity} />

      <Card>
        <form onSubmit={handleSubmit(login)}>
          <div className="space-y-6">
            <div>
              <h2 className="mb-1">
                {t('auth:welcome_back')}
              </h2>
              <p className="text-slate-700">
                {t('auth:log_in_intro')}
              </p>
            </div>

            {serverErrors && <ServerErrors errors={serverErrors} defaultValues={defaultValues} scrollIntoView />}

            <InputGroup>
              <InputLabel required valid={!errors.email} htmlFor="login_email">
                {t('shared:form.email_address')}
              </InputLabel>
              <Input
                {...register('email', { required: true })}
                inputMode="email"
                id="login_email"
                aria-invalid={!!errors.email}
              />
              <ErrorMessage attribute={t('shared:form.email_address')} error={errors.email }/>
            </InputGroup>

            <Button
              type="submit"
              disabled={loading}
              className="block w-full"
              loading={loading}
            >
              {t('auth:log_in')}
              <Icon className="ml-2">
                <ChevronRight />
              </Icon>
            </Button>
          </div>
        </form>
      </Card>
    </Container>
  );
}

LoginPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
