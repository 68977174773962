import '../css/app.css';

import { createInertiaApp, router } from '@inertiajs/react';
import i18n from 'i18next';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';

import { SharedProps } from './Backend/types';
import { disableBackForwardCaching } from './Shared/inertia';
import RollBarProvider from './Shared/Rollbar/RollbarProvider';

const container = document.getElementById('app');

if (container) {
  const root = createRoot(container);

  createInertiaApp<SharedProps>({
    resolve: (name) => {
      const pages = import.meta.glob('./Auth/**[!__]/*.tsx', { eager: true });
      return pages[`./${name}.tsx`];
    },
    title: (title) => `${title ? `${title} – ` : ''}Supporta`,
    setup({ App, props }) {
      const { locale, translations } = props.initialPage.props;

      i18n
        .use(initReactI18next)
        .init({
          resources: {
            [locale as string]: translations,
          },
          lng: locale as string,
          interpolation: {
            escapeValue: false,
          },
        });

      disableBackForwardCaching(router);

      root.render(
        <RollBarProvider>
          <App {...props} />
        </RollBarProvider>
      );
    },
  });
}
